import React from "react";
import AQI from "./AQI";
import '../App.css';

const MajorDetails = ({jsonData, data, index}) => {

    return(
        <div className='Major_Details'>
            {jsonData.adv && (data[index].params.temp !== 'undefined' || data[index].params.temperature !== 'undefined') && (data[index].params.humid || data[index].params.humidity) ? 
                (
                    <div id='temp-humid-display'>
                        <div id='temp-display'>
                            <img src="/img/temp.png" alt='Temperature' id='temp_icon'></img>
                                {(data[index].params !== undefined) ? 
                                    (data[index].params.temp !== undefined ? 
                                        (<div id='temp'>{Math.round(data[index].params.temp * 100) / 100} &deg;C</div>) :
                                        (data[index].params.temperature !== undefined ? 
                                            (<div id='temp'>{Math.round(data[index].params.temperature * 100) / 100} &deg;C</div>) :
                                            (<div id='temp'>Temp</div>)
                                        )
                                    )
                                    :
                                    (<div id='temp'>Temp</div>)
                                }
                        </div>
                        <div id='humid-display'>
                            <img src="/img/humid.png" alt='Humidity' id='humid_icon'></img>
                                {(data[index].params !== undefined) ? 
                                    (data[index].params.humid !== undefined ? 
                                        (<div id='humidity'>{Math.round(data[index].params.humid * 100) / 100} %</div>) :
                                        (data[index].params.humidity !== undefined ? 
                                            (<div id='humidity'>{Math.round(data[index].params.humidity * 100) / 100} %</div>) :
                                            (<div id='humidity'>Humid</div>)
                                        )
                                    )
                                    :
                                    (<div id='humidity'>Humid</div>)
                                }
                        </div>
                    </div>
                ) 
                :(
                    <div id='temp-humid-display'>
                        <div id='temp-display'>
                            <img src="/img/temp.png" alt='Temperature' id='temp_icon'></img>
                                {(data[index].params !== undefined) ? 
                                    (data[index].params.temp !== undefined ? 
                                        (<div id='temp'>{Math.round(data[index].params.temp * 100) / 100} &deg;C</div>) :
                                        (data[index].params.temperature !== undefined ? 
                                            (<div id='temp'>{Math.round(data[index].params.temperature * 100) / 100} &deg;C</div>) :
                                            (<div id='temp'>NA &deg;</div>)
                                        )
                                    )
                                    :
                                    (<div id='temp'>Temp</div>)
                                }
                        </div>
                        <div id='humid-display'>
                            <img src="/img/humid.png" alt='Humidity' id='humid_icon'></img>
                                {(data[index].params !== undefined) ? 
                                    (data[index].params.humid !== undefined ? 
                                        (<div id='humidity'>{Math.round(data[index].params.humid * 100) / 100} %</div>) :
                                        (data[index].params.humidity !== undefined ? 
                                            (<div id='humidity'>{Math.round(data[index].params.humidity * 100) / 100} %</div>) :
                                            (<div id='humidity'>NA %</div>)
                                        )
                                    )
                                    :
                                    (<div id='humidity'>Humid</div>)
                                }
                        </div>
                    </div>
                )
            }
            <div id="aqi-res-display">
                <div className="AQI" id="aqi-display"  style={{backgroundColor : data[index].color['aqi'] }}>
                    <div>
                        {!jsonData.param ?
                            <section>AQI</section>:
                            <section>PM<sub>2.5</sub></section>
                        }
                        {data[index].aqi ? 
                            data[index].aqi.hasOwnProperty('pm2.5') ?
                            <p id="actual_data">{Math.round(data[index].aqi['pm2.5'].avg)}</p> :
                            data[index].aqi.hasOwnProperty('aqi') ?
                            <p id="actual_data">{Math.round(data[index].aqi['aqi'])}</p> :
                            <p id="actual_data">{Math.round(data[index].aqi)}</p>
                            :
                            <p id="actual_data">NA</p>
                        }
                    </div>
                </div>
                <AQI data={data[index]} flag={jsonData.paging}/>
            </div>
        </div>
    )
}

export default MajorDetails;