import React from "react";

const AQIDisplay = ({data}) => {

    var item;
    if(data.aqi === undefined || data.aqi === 'NA'){

        item = "NA";

    }
    else if(typeof data.aqi === 'object' && data.aqi['pm2.5'] && data.aqi['pm2.5'].avg !== undefined){
        
        item = data.aqi['pm2.5'].avg;
        
    }
    else{

        item = data.aqi;
        
    }


    return(

        <>
            {(item === 0 || item === undefined) && 
                <>
                    <img src="/img/none-aqi-image.png" alt='None' id="condition-icon"/>
                    <p></p>
                </>
            }
            {item > 0 && item <= 50 && 
                <>
                    <img src="/img/good-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Good</p>
                </>
            }
            {item > 50 && item <= 100 && 
                <>
                    <img src="/img/satisfactory-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Satisfactory</p>
                </>
            }
            {item > 100 && item <= 200 &&
                <>
                    <img src="/img/moderate-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Moderate</p>
                </>
            }
            {item > 200 && item <= 300 &&
                <>
                    <img src="/img/poor-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Poor</p>
                </>
            }
            {item > 300 && item <= 400 &&
                <>
                    <img src="/img/verypoor-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Very Poor</p>
                </>
            }
            {item > 400 && item <= 500 &&
                <>
                    <img src="/img/severe-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Severe</p>
                </>
            }
            {item > 500 &&
                <>
                    <img src="/img/severe-aqi-image.png" alt='None' id="condition-icon"/>
                    <p>Severe</p>
                </>
            }
    </>

    )
    
}

export default AQIDisplay;