import React from "react";

const AQI = ({data, flag}) => {
    const item = {data}.data;

    return(
        <>
            {flag ? (
                <>
                        {(typeof(item.aqi) === 'undefined' || item.aqi === 'NA') &&
                            <div className="Result" id="paging-res">
                                <img src="/img/none-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p></p>
                            </div>
                        }
                        {item.aqi > 0 && item.aqi <= 50 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/good-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Good</p>
                            </div>
                        }
                        {item.aqi > 50 && item.aqi <= 100 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/satisfactory-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Satisfactory</p>
                            </div>
                        }
                        {item.aqi > 100 && item.aqi <= 200 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/moderate-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Moderate</p>
                            </div>
                        }
                        {item.aqi > 200 && item.aqi <= 300 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/poor-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Poor</p>
                            </div>
                        }
                        {item.aqi > 300 && item.aqi <= 400 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/verypoor-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Very Poor</p>
                            </div>
                        }
                        {item.aqi > 400 && item.aqi <= 500 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/severe-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Severe</p>
                            </div>
                        }
                        {item.aqi > 500 &&  
                            <div className="Result" id="paging-res">
                                <img src="/img/severe-aqi-image.png" alt='None' id="paging-condition-icon"/>
                                <p>Severe</p>
                            </div>
                        }
                </>
                )
                : 
                (
                    <>
                        {item.aqi === undefined || item.aqi === 0 &&
                            <div className="Result" id="major-res">
                                <img src="/img/none-aqi-image.png" alt='None' id="condition-icon"/>
                                <p></p>
                            </div>
                        }
                        {item.aqi > 0 && item.aqi <= 50 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/good-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Good</p>
                            </div>
                        }
                        {item.aqi > 50 && item.aqi <= 100 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/satisfactory-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Satisfactory</p>
                            </div>
                        }
                        {item.aqi > 100 && item.aqi <= 200 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/moderate-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Moderate</p>
                            </div>
                        }
                        {item.aqi > 200 && item.aqi <= 300 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/poor-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Poor</p>
                            </div>
                        }
                        {item.aqi > 300 && item.aqi <= 400 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/verypoor-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Very Poor</p>
                            </div>
                        }
                        {item.aqi > 400 && item.aqi <= 500 &&  
                            <div className="Result" id="major-res">
                                <img src="/img/severe-aqi-image.png" alt='None' id="condition-icon"/>
                                <p>Severe</p>
                            </div>
                        }
                    </>
                )
            }
        </>
    )

}

export default AQI;