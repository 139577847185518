import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import jsonData0 from './Data_file/aes_11878.json';
import jsonData1 from './Data_file/aes_11879.json';
import jsonData2 from './Data_file/aes_11880.json';
import jsonData3 from './Data_file/aes_11881.json';
import jsonData4 from './Data_file/airth.json';
import jsonData5 from './Data_file/ayodhya.json';
import jsonData6 from './Data_file/cars24.json';
import jsonData7 from './Data_file/lti_mindtree.json';
import jsonData8 from './Data_file/tataariana.json';
import jsonData9 from './Data_file/ubreathe.json';
import jsonData10 from './Data_file/ultrapure.json';
import jsonData11 from './Data_file/ultrapure1.json';
import jsonData12 from './Data_file/template.json';
import jsonData13 from './Data_file/demo_omniion.json';
import jsonData14 from './Data_file/demo.json';
import jsonData15 from './Data_file/demo_9RN64GPE.json';
import jsonData16 from './Data_file/driiv.json';
import jsonData17 from './Data_file/driiv1.json';
import jsonData18 from './Data_file/ubreathe_new.json';
import jsonData19 from './Data_file/aes_13277.json';
import jsonData20 from './Data_file/demo1.json';
import jsonData21 from './Data_file/ubreathe1.json';
import jsonData22 from './Data_file/aes_11875.json';
import jsonData23 from './Data_file/ucan_centurion.json';
import jsonData24 from './Data_file/tdav_LBAZHT0G.json';
import jsonData25 from './Data_file/tdav_ZJRXKBYL.json';
import jsonData26 from './Data_file/tdav_R90J081M.json';
import jsonData27 from './Data_file/tdav_9RWJOVC5.json';
import jsonData28 from './Data_file/tdav_4X8I08SG.json';
import jsonData29 from './Data_file/tdav_Y060A0NP.json';
import jsonData30 from './Data_file/tdav_8IM301X6.json';
import jsonData31 from './Data_file/tdav_GEXFPYY4.json';
import jsonData32 from './Data_file/tdav_QPISM9E8.json';
import jsonData33 from './Data_file/demo_testing.json';
import jsonData34 from './Data_file/aes_14524.json';
import jsonData35 from './Data_file/aes_shoba_housing.json';
import jsonData36 from './Data_file/aes_four_devices.json';

const searchPath = window.location.pathname;

const root = ReactDOM.createRoot(document.getElementById('root'));
var val;

if(searchPath === '/aes_11878'){
  val = jsonData0
}
else if (searchPath === '/aes_11879') {
  val = jsonData1
}
else if (searchPath === '/aes_11880') {
  val = jsonData2
}
else if (searchPath === '/aes_11881') {
  val = jsonData3
}
else if (searchPath === '/airth') {
  val = jsonData4
}
else if (searchPath === '/ayodhya') {
  val = jsonData5
}
else if (searchPath === '/cars24') {
  val = jsonData6
}
else if (searchPath === '/lti_mindtree') {
  val = jsonData7
}
else if (searchPath === '/tataariana' || searchPath === '/tata') {
  val = jsonData8
}
else if (searchPath === '/ubreathe') {
  val = jsonData9
}
else if(searchPath === '/ultrapure'){
  val = jsonData10
}
else if(searchPath === '/ultrapure1'){
  val = jsonData11
}
else if(searchPath === '/'){
  val = jsonData12
}
else if(searchPath === '/demo_omniion'){
  val = jsonData13
}
else if(searchPath === '/demo'){
  val = jsonData14
}
else if(searchPath === '/demo_9RN64GPE'){
  val = jsonData15
}
else if(searchPath === '/driiv'){
  val = jsonData16
}
else if(searchPath === '/driiv1'){
  val = jsonData17
}
else if(searchPath === '/ubreathe_new'){
  val = jsonData18
}
else if(searchPath === '/aes_13277'){
  val = jsonData19
}
else if(searchPath === '/demo1'){
  val = jsonData20
}
else if(searchPath === '/ubreathe1'){
  val = jsonData21
}
else if(searchPath === '/aes_11875'){
  val = jsonData22
}
else if(searchPath === '/ucan_centurion'){
  val = jsonData23
}
else if(searchPath === '/tdav_LBAZHT0G'){
  val = jsonData24
}
else if(searchPath === '/tdav_ZJRXKBYL'){
  val = jsonData25
}
else if(searchPath === '/tdav_R90J081M'){
  val = jsonData26
}
else if(searchPath === '/tdav_9RWJOVC5'){
  val = jsonData27
}
else if(searchPath === '/tdav_4X8I08SG'){
  val = jsonData28
}
else if(searchPath === '/tdav_Y060A0NP'){
  val = jsonData29
}
else if(searchPath === '/tdav_8IM301X6'){
  val = jsonData30
}
else if(searchPath === '/tdav_GEXFPYY4'){
  val = jsonData31
}
else if(searchPath === '/tdav_QPISM9E8'){
  val = jsonData32
}
else if(searchPath === '/demo_testing'){
  val = jsonData33
}
else if(searchPath === '/aes_14524'){
  val = jsonData34
}
else if(searchPath === '/shoba_housing'){
  val = jsonData35
}
else if(searchPath === '/aes_four_devices'){
  val = jsonData36
}
else{
  val = jsonData12
}

root.render(

  // For development
  // <App reqData={val} />


  // For production
  <React.StrictMode>
    <App reqData={val}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
