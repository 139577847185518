import React from "react";

const Mobile_Footer = ({props}) => {

    return(
        <div id='mobile-footer'>
            <img src='/img/aqi_scale.png' alt='AQI Scale' id='mobile-aqi-scale' />
            <div id='mobile-partner-colab-container'>
                <div id='mobile-partner-container'>
                    {props.partnerIcon !== '' ?
                        (
                            <img src={props.partnerIcon} id='mobile-partnerIcon' alt='Partner' />
                        )
                        : null
                    }
                    {props.partnerText !== '' ?
                        (
                            <h5 id='mobile-partnerText'>{props.partnerText}</h5>
                        )
                        :null
                    }
                </div>
                <div id='mobile-colab-container'>
                    {props.colabIcon !== '' ? 
                        (
                            <img id='mobile-colabIcon' src={props.colabIcon} alt='Colab' />
                        )
                        :null
                    }
                    {props.colabText !== '' ? 
                        (
                            <h5 id='mobile-colabText'>{props.colabText}</h5>
                        )
                        :null
                    }
                </div>
            </div>
        </div>
    )

}

export default Mobile_Footer;