import React from "react";

const Header = (props) => {

    const data = props.data;
    const time = props.time;

    return(
        <div id="header" className="d-flex justify-content-center justify-content-lg-between px-4">
            <div className=" d-none d-lg-flex justify-content-center align-items-center mb-1">
                <h5 id="time" className="align-self-center m-0 font-weight-bold align-self-center m-0">{time}</h5>
            </div>
            <div id="logo" className="d-flex align-self-center">
                <img src={data.ownLogo} id="ownLogo" />
                {data.customerLogo !== '' ? 
                    (
                        <>
                            <div className="vr divider mx-4"></div>
                            <img src={data.customerLogo} id="customerLogo" className="img-responsive plogo" alt="Customer Logo" />
                        </>
                    )
                    :null
                }
            </div>
            <div className="d-none d-lg-flex justify-content-center align-items-center mb-1">
                {data.comparisonMode === 2 ? (
                    <h5 id="city" className="align-self-center m-0 font-weight-bold align-self-center m-0">{data.outdoorLocation}</h5>
                ):
                (
                    <h5 id="city" className="align-self-center m-0 font-weight-bold align-self-center m-0">{data.city}</h5>
                )}
            </div>
        </div>
    )

}
export default Header;