import React from "react";
import '../App.css';

const Loading = () => {

    return(
        <>
            <div id="loading">
                <img src="/img/logo_banner.png" alt="Aurassure"></img>
            </div>
        </>
    )

}
export default Loading